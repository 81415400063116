import React from 'react'
import { Seo } from '../components/shared/seo'
import { useTheme } from '../theme/use-theme'
import { useI18n, TranslationBundles } from '../i18n/i18n-context'
import { Flex, Text } from 'baselift'
import { useBreakpoints } from '../context/breakpoints-context'

const translations: TranslationBundles = {
  'en-US': {
    namespace: 'notFound',
    translations: {
      seo: {
        title: 'Not Found',
        description: 'HTTP 404 Not Found',
        pathname: '/404',
      },
      title: 'Woops',
      message: "This page doesn't exist.",
    },
  },
}

const NotFoundPage = () => {
  const theme = useTheme()
  const { t } = useI18n(translations)
  const { bp } = useBreakpoints()

  return (
    <>
      <Seo
        title={t('notFound:seo.title')}
        description={t('notFound:seo.description')}
        pathname={t('notFound:seo.pathname')}
      />
      <Flex
        css={bp({
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          marginTop: [theme.space('m'), theme.space('l')],
        })}
      >
        <Text
          element="h1"
          css={bp({
            fontSize: [theme.fontSizes[6], theme.fontSizes[7]],
          })}
        >
          {t('notFound:title')}
        </Text>
        <Text>{t('notFound:message')}</Text>
      </Flex>
    </>
  )
}

export default NotFoundPage
